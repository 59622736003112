.pricing {
  padding: 80px 0;
  background: white;
  margin: 0 auto;
}

.pricing-title {
  font-size: var(--header-font-size);
  font-weight: 700;
  color: var(--brand-blue);
  margin-bottom: 48px;
  text-align: left;
}

.pricing-cards-wrapper {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 0 20px;
}

.pricing-card {
  border: 1px solid var(--brand-blue);
  border-radius: 12px;
  padding: 24px;
  width: 331px; /* Fixed width for each card */
  background: white;
  display: flex;
  flex-direction: column;
}

.pricing-card-title {
  font-size: var(--body-font-size);
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
}

.pricing-amount {
  text-align: center;
  margin-bottom: 16px;
}

.price {
  font-size: 48px;
  font-weight: 700;
}

.period {
  font-size: 20px;
}

.pricing-features {
  flex-grow: 1;
}

.pricing-features li {
  text-align: left;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}

.coming-soon-btn {
  width: 137px;
  padding: 12px;
  font-size: 16px;
  background-color: var(--brand-blue);
  opacity: 0.55;
  border: none;
  border-radius: 6px;
  color: white;
  align-self: center;
  font-weight: 700;
}

/* Responsive adjustments */
@media (max-width: 575px) {
  .pricing-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .pricing-card {
    width: 100%; /* Make cards full width on mobile */
  }
}
