.hero {
  /* min-height: calc(100vh - 400px); */
  padding-top: 64px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.hero-inner {
  margin: 0 auto;
}

/* Content positioning */
.hero-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 100px;
}

.hero-title {
  font-size: 99px;
  line-height: 106.92px;
  color: var(--brand-blue);
  text-align: left;
}

.small-title {
  font-size: 40px;
}

.bold-title {
  font-weight: 800;
}

.big-title {
  font-size: 126px;
  line-height: 136.92px;
}

/* Form alignment */
.form-wrapper {
  max-width: 100%;
}

.email-signup {
  width: 100%;
  margin-top: 2.5rem;
  gap: 8px;
}

.email-signup .form-control {
  min-width: 290px;
  font-weight: 500;
  border-radius: var(--corner-small);
}

/* Image positioning */
.hero-images {
  position: relative;
  width: 100%;
  overflow: visible;
  right: 50px;
}

.image-container {
  position: relative;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.phone-left {
  position: relative;
  bottom: 75px;
  z-index: 2;
  width: 300px;
  margin-right: 10px;
}

.phone-right {
  position: relative;
  top: 50px;
  z-index: 1;
  width: 300px;
}

/* Button styling */
.join-waitlist-btn.btn.btn-primary.btn-lg {
  background: var(--gradient-blue);
  background-blend-mode: soft-light, normal;
  color: white;
  font-weight: 700;
  border: none;
  font-size: 20px;
  padding: 8px 16px;
  border-radius: var(--corner-small);
  line-height: 30px;
  white-space: nowrap;
  margin-left: 0px;
}

@media (max-width: 1199px) {
  .hero {
    padding-bottom: 2rem;
    margin-bottom: -120px;

    .hero-images {
      top: -150px;
      left: 30px;
    }

    .phone-left {
      display: none;
    }

    .phone-right {
      width: 275px;
    }

    .hero-content {
      padding-top: 0;
    }

    .hero-title {
      font-size: 64px;
      line-height: 70.92px;
    }

    .small-title {
      font-size: 26px;
    }

    .big-title {
      font-size: 80px;
      line-height: 86.92px;
    }

    .text-wrapper,
    .form-wrapper {
      margin: 0 auto;
      text-align: center;
    }

    .join-waitlist-btn-wrapper {
      justify-content: center;

      .join-waitlist-btn {
        margin-top: 8px;
      }
    }
  }
}

@media (max-width: 767px) {
  .main-content {
    position: relative;
    top: -270px;
  }

  .hero {
    padding-bottom: 2rem;
    margin-bottom: 0;

    .hero-images {
      top: 0;
      left: 0;
    }

    .phone-left {
      display: none;
    }

    .hero-content {
      padding-top: 0;
    }

    .hero-title {
      font-size: 60px;
      line-height: 66.92px;
    }

    .small-title {
      font-size: 24px;
    }

    .big-title {
      font-size: 76px;
      line-height: 76px;
    }

    .text-wrapper,
    .form-wrapper {
      margin: 0 auto;
      text-align: center;
    }

    .join-waitlist-btn-wrapper {
      justify-content: center;

      .join-waitlist-btn {
        margin-top: 8px;
      }
    }
  }
}
