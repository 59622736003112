.navbar {
  width: 100%;
  padding: 16px 0;
  display: flex !important;
  background: #fff;
}

.navbar-brand-wrapper {
  position: absolute;
  left: 20px; /* Give it some padding from the left */
}

.navbar-inner {
  display: flex;
  margin: 0 auto; /* Center the navbar-inner */
}

/* Navigation items */
.nav-link {
  font-weight: 700 !important;
  color: #222222 !important;
  padding: 8px 16px !important;
}

/* Dropdown styling */
.dropdown-menu {
  padding: 8px 0;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 8px 16px;
}

/* Button alignment and spacing */
.navbar-collapse {
  justify-content: space-between;
}

.navbar-toggler {
  border: none !important;

  &:focus {
    box-shadow: none !important;
  }

  .navbar-toggler-icon {
    background-image: url('../../assets/images/menu-icon.png') !important;
  }
}

.join-waitlist-btn-wrapper {
  display: flex;
  justify-content: flex-end;

  .nav-link {
    padding-right: 0 !important;
  }
}

.join-waitlist-btn.btn.btn-primary {
  background: var(--gradient-blue);
  background-blend-mode: soft-light, normal;
  color: white;
  font-weight: 700;
  border: none;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: var(--corner-small);
  line-height: 16px;
  margin-left: 16px;
}

/* Mobile responsiveness */
@media (max-width: 1199px) {
  .navbar-inner {
    width: 593px; /* Your desired fixed width */
  }
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .navbar-inner {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-inner {
    display: flex;
    text-align: left;

    .nav-link {
      padding-left: 0 !important;
    }

    #features-dropdown::after {
      display: none !important;
    }

    .main-nav-container .navbar-collapse .navbar-nav .nav-item.dropdown {
      .dropdown-menu.show {
        border: none;
        background: transparent;
        padding-left: 1rem;
        box-shadow: none;
      }
    }

    /* Hide dropdown toggle arrow */
    .navbar .dropdown-toggle::after {
      display: none !important;
    }

    /* Make dropdown button look like regular text */
    .navbar .dropdown-toggle {
      pointer-events: none;
    }
  }

  .navbar .join-waitlist-btn-wrapper {
    display: none;

    &.show {
      display: flex;
      justify-content: flex-start;

      .join-waitlist-btn {
        margin-left: 0 !important;
      }
    }
  }
}
