/* More specific selector to override Bootstrap */
.toast.show.fade {
  background-color: #222222 !important;
  z-index: 1050 !important;
  position: fixed !important;
  top: -3px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;

  .toast-header {
    background-color: #222222 !important;
    color: #fff !important;
    font-size: 12px !important;
  }
}
