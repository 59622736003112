.App {
  text-align: center;
}

.navbar .navbar-inner,
.main-content section {
  width: 100%;
  max-width: 1040px;
}

.blue-curve-wrapper {
  width: 100%;
  overflow: hidden;
}

.blue-curve-wrapper img {
  width: 100%;
  display: block;
}

.footer-wrapper {
  padding: 8px 32px;
  background-color: var(--footer-background-color);
  color: #fff;

  p {
    margin-bottom: 0;
    text-align: left;
  }
}

@media (max-width: 1199px) {
  .navbar .navbar-brand-wrapper {
    position: relative;
    left: 0;
  }
}

@media (max-width: 767px) {
  .main-content section {
    width: 100%;
    padding: 32px 16px;
  }

  .footer-wrapper {
    padding: 8px;

    p {
      text-align: center !important;
    }
  }
}
