/* Your custom styles go here */

/* Set as root font */
:root {
  --font-family-base: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

  --corner-small: 8px;
  --corner-large: 16px;

  --title-font-size: 160px;
  --header-font-size: 32px;
  --header-font-size-mobile: 24px;
  --body-font-size: 20px;
  --note-font-size: 16px;

  --brand-blue: #075ad8;
  --brand-black: #222222;
  --footer-background-color: #a7b4c9;
  --gradient-blue: linear-gradient(0deg, #075ad8, #075ad8),
    linear-gradient(
      153.64deg,
      rgba(167, 180, 201, 0) 21.42%,
      rgba(167, 180, 201, 0.7) 104.19%
    );
}

/* Apply to everything */
* {
  font-family: var(--font-family-base);
}

/* Or apply to body (recommended) */
html,
body {
  margin: 0;
  font-family: var(--font-family-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  scroll-padding-top: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* If using Bootstrap, you can override its font-family variable */
:root {
  --bs-font-sans-serif: 'Plus Jakarta Sans', system-ui, -apple-system,
    sans-serif;
  --bs-body-color: var(--brand-black) !important;
}
