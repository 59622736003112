.about {
  padding: 80px 0;
  background: white;
  margin: 0 auto;
  text-align: left;
}

.about-title {
  font-size: var(--header-font-size);
  font-weight: 700;
  color: #111827;
  text-align: center;
  margin-bottom: 48px;
}

.about-text {
  font-size: var(--body-font-size);
  line-height: 1.6;
  color: #374151;
  margin-bottom: 24px;
}

.about-text:last-child {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .about-text {
    font-size: 16px;
  }
}
