.join-waitlist {
  padding: 80px 0;
  margin: 0 auto;
  margin-bottom: 20vh;
}

.waitlist-container {
  max-width: 704px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 96px;
}

.app-info {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
}

.app-icon {
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

.app-text h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
  line-height: 40px;
}

.app-text p {
  font-size: 20px;
  margin: 0;
  text-align: left;
  line-height: 30px;
}

.waitlist-form {
  width: 100%;
}

.email-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: var(--corner-small);
}

.join-button {
  padding: 12px 24px;
  font-weight: 600;
  white-space: nowrap;
  background: var(--brand-blue);
  border: none;
}

@media (max-width: 1199px) {
  .waitlist-container {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .app-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
  }

  .app-text p {
    text-align: center !important;
  }
}

@media (max-width: 767px) {
  .join-waitlist {
    margin-bottom: 2vh !important;

    * {
      font-size: 16px !important;
    }
  }

  .app-text h2 {
    font-size: 24px !important;
    line-height: 30px;
  }

  .app-text p {
    font-size: 16px;
    line-height: 24px;
  }

  .waitlist-container {
    gap: 24px;
  }

  .waitlist-form form {
    flex-direction: column;
    gap: 12px !important;
  }

  .join-waitlist-btn {
    line-height: 24px !important;
  }
}
