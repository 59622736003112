.survey-modal .modal-content {
  border-radius: 16px;
  border: none;
}

.survey-modal .modal-header {
  padding: 24px 24px 12px;
}

.survey-modal .modal-title {
  font-size: 24px;
  font-weight: 600;
}

.survey-modal .modal-body {
  padding: 24px;
}

.survey-modal .form-label {
  font-weight: 500;
  margin-bottom: 8px;
}

.survey-modal .form-control,
.survey-modal .form-select {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.survey-modal .form-check {
  margin-right: 16px;
}

.survey-modal .form-check-input {
  margin-right: 8px;
}

.survey-modal .btn-primary {
  padding: 12px 32px;
  font-weight: 600;
}

@media (max-width: 768px) {
  .survey-modal .d-flex {
    flex-direction: column;
  }

  .survey-modal .gap-3 {
    gap: 1rem !important;
  }
}
